/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.quotation {
    text-align: center;
    font-size: 16px;
    font-family: Helvetica, Arial, sans-serif;
    margin-top: 0;
}

.sizeW3 {
    width: 85px !important;
    display: inline !important;
    margin-right: 5px;
}


.sizeW4 {
    width: 120px !important;
    display: inline !important;
}

.w100 {
    width: 100%;
}

.floatRight{
    float: right;
}

.floatLeft {
    float: left;
}

textarea {
    border: 1px solid rgba(0, 0, 0, 0.125)
}

.mInfo {
    color: #0026ff;
}

.mError {
    color: #E01A76;
}